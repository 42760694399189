@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #FF6C3B 1.84%, #f7ca49 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #FF6C3B -13.86%, #f7ca49 99.55%);
  
  --color-bg: #001510;
  --color-footer : #02362A;
  --color-text: #ffff;
  --color-subtext: #f7ca49;
}