.jg__problem {
    padding: 0 0 2rem 0;
}

.jg__problem-card {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 2rem;
    background: radial-gradient(circle at 71% 52%, #145446 15%, #02362A 55%);
}

.jg__problem-break {
    display: flex;
    padding: 2rem;

    color: var(--color-text);
}

.jg__problem-card__heading {
    padding-bottom: 1rem;
}

.jg__problem-card__heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
}

.jg__problem-card__container {
    display: flex;
    gap: 1rem;
}

.jg__problem-card__container p {
    flex: 1;
    color: var(--color-text);
    
}

.jg__problem-card__container-item div {
    width: 38px;
    height: 3px;

    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 1050px) {
    .jg__problem-card__container {
        flex-direction: column;
    }

    .jg__problem-card__heading h1 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (max-width: 700px) {
    
}

@media screen and (max-width: 550px) {
    
}


