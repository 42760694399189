.jg__wahl {
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.jg__wahl h1 {
    padding-top: 6rem;
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.jg__wahl-image {
    width: 100%;
    max-width: 900px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.jg__wahl-text {
    font-size: 16px;
    max-width: 850px;
    width: 100%;
    margin: 1rem 0 1rem 0; 
}

.jg__wahl-image, .jg__wahl-text {
    display: flex;
    align-self: center;
}

@media screen and (max-width: 650px) {

    .jg__wahl h1 {
        font-size: 48px;
        line-height: 60px;
    }
}

@media screen and (max-width: 490px) {

    .jg__wahl h1 {
        font-size: 36px;
        line-height: 48px;
    }
}