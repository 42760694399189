* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

p {
  font-size: 20px;
  line-height: 27px;
}

/*kommentar*/

.gradient__bg {
    /*background-color: #012622;
    background: url('./assets/ticket-2974645.jpg') center center no-repat;*/
    
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 100% 100%, #FF242400 0%, #02362A 49%), url('./assets/ratke-gebaeude.jpg');

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 100% 100%, #FF242400 0%, #02362A 49%), url('./assets/ratke-gebaeude.jpg');

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 100% 100%, #FF242400 0%, #02362A 49%), url('./assets/ratke-gebaeude.jpg');

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 100% 100%, #FF242400 0%, #02362A 49%), url('./assets/ratke-gebaeude.jpg');

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 100% 100%, #FF242400 0%, #02362A 49%), url('./assets/ratke-gebaeude.jpg');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 0 6rem 0 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }

    .section__margin {
      margin: 0 4rem 0rem 4rem;
    }

    p {
      font-size: 16px;
      line-height: 24px;
  }
  }

  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }

    .section__margin {
      margin: 0 2rem 0rem 2rem;
    }

    p {
      font-size: 14px;
      line-height: 24px;
  }
  }