.jg__stimmen {
    display: flex;
    flex-direction: column;
    color: var(--color-text);
}

.jg__stimmen-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 4rem;
}

.jg__stimmen h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    padding-bottom: 1rem;
}