.jg__break {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.jg__break-content {
    flex: 1;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.jg__break-content h1 {
    color: var(--color-text);
    font-weight: 400;
    font-style: italic;

    font-size: 35px;
    line-height: 40px;
    letter-spacing: -0.04em;
}

@media screen and (max-width: 650px) {
    .jg__break-content h1 {
        font-size: 25px;
        line-height: 28px;
    }
}

@media screen and (max-width: 490px) {
    .jg__break-content h1 {
        font-size: 18px;
        line-height: 28px;
    }
}