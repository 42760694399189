.jg__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    width: 100%;
    top: 0;
    transition: background-color 0.3s ease;
    padding: 1.5rem 6rem 1rem 6rem;
}

.navbar__background {
    background-color: rgba(0, 0, 0, 0.8);
  }

.jg__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.jg__navbar-links_logo {
    margin-right: 2rem;
}

.jg__navbar-links_logo img {
    height: 35px;
}

.jg__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.jg__navbar-lang {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.jg__navbar-lang button {
    background: none;
    border: none;
    min-width: 30px;

    cursor: pointer;
}

.jg__navbar-links_container p,
.jg__navbar-lang p,
.jg__navbar-menu_container p {
    color: var(--color-text);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.jg__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.jg__navbar-menu svg {
    cursor: pointer;
}

.jg__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.jg__navbar-menu_container p {
    margin: 1rem 0;
}

@media screen and (max-width: 1050px) {
    .jg__navbar-links_container {
        display: none;
    }
    .jg__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .jg__navbar {
        padding: 1.5rem 4rem 1rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .jg__navbar {
        padding: 1.5rem 2rem 1rem 2rem;
    }

    .jg__navbar-menu_container {
        top: 20px;
    }
}